import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components'


const topColor = "#1d9bf0";
const bottomColor = "#0a5f98";

const Tweet = styled.button<{ muskCoin: number }>`
padding: 17px 20px;
border-radius: 10px;
border: 0;
cursor: pointer;
width: auto;
background-color: ${topColor};
font-size: 15px;
min-width: 200px;
transition: all .3s ease;
box-shadow: ${bottomColor} 0px 10px 0px 0px;
color: #fff;
font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
    ${({ muskCoin }) => {
        if (muskCoin <= 0) {
            return `
            animation: shake 2s;
            animation-iteration-count: infinite;
            `
        }
    }}
  @keyframes shake {
    0% {
      transform: scale(1,1);
    }
    25% {
      transform: scale(1,1);
    }
    50% {
      transform: scale(0.9,0.9);
    }
    75% {
      transform: scale(1,1);
    }
    100% {
      transform: scale(1,1);
    }
  }




&:hover {
    border-color: rgba(0, 0, 0, 0.15);
    transform: translateY(-1px);
}

&:active {
    background-color: ${topColor};
    /*50, 168, 80*/
    box-shadow: ${bottomColor} 0px 0px 0px 0px;
    transform: translateY(5px);
    transition: 200ms;
}`

const TweetButton = ({ handleClickTweet, text, muskCoin }) => {
    return <Tweet className="tweet" onClick={handleClickTweet} muskCoin={muskCoin}>{text}</Tweet>
}
export { TweetButton };