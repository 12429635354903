import confetti from 'canvas-confetti';
// Convert a number to a currency string eg 1,000,000.00
// Always show 2 decimal places
const numberToCurrency = (number: number, minimumFractionDigits = 2, maximumFractionDigits = 2) =>
    number.toLocaleString(undefined, {
        minimumFractionDigits,
        maximumFractionDigits,
    });


// Shorten 1000 to 1k and 1000000 to 1m
function shortenNumber(number: number) {
    if (number >= 1000000) {
        return `${(number / 1000000).toFixed(1)}m`;
    }
    if (number >= 1000) {
        return `${(number / 1000).toFixed(1)}k`;
    }
    return numberToCurrency(number, 0, 2);
}

function confettiCanvas(x, y, type?) {
    const { innerWidth, innerHeight } = window;

    const defaults = { origin: { x: x / (innerWidth), y: y / innerHeight }, }

    function shoot() {
        if (type === "upgrade") {
            confetti({
                ...defaults,
                particleCount: 60,
                spread: 70,
            })
        } else {
            confetti({
                ...defaults,
                spread: 360,
                ticks: 50,
                gravity: 0.3,
                decay: 0.94,
                startVelocity: 30,
                shapes: ['star'],
                colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8'],
                particleCount: 10,
                scalar: 0.4,
            });
        }
    }
    setTimeout(shoot, 0);
}


function bankruptcy() {
    const duration = 15 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
        return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
            return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
    }, 250);
}


export { numberToCurrency, shortenNumber, confettiCanvas, bankruptcy };

