import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import LogoImg from "../assets/elon-logo.png";
import fonts from "../constants/fonts.json"

const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    // vertical centre grid items
    align-items: center;
`
const Description = styled.div`
    text-align:center;
`
const Title = styled.div`
text-align:center;
@media (max-width: 768px) {
    // text-align:left;
}
& h1, & h2{
    font-family: 'Red Rose', cursive;
    font-weight: 800;
}
`;

const Subtitle = styled.div`
text-align:center;
h2{
    font-family: 'Red Rose', cursive;
    font-weight: 500;
}`


const Logo = styled.img`
width:100%;
max-width:80px;
`

const Top = styled.div`
    display:grid;
    justify-content: center;
    align-content: center;
    gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: unset;
    align-items: center;
}
`

const Header = ({ }) => {
    return (
        <HeaderRow>
            <Top>
                <Logo src={LogoImg} alt="Elon Musk" />
                <Title>
                    <h1>Elon Quest</h1>
                </Title>
            </Top>

            <Subtitle><h2><i>Everything Musk Go!</i></h2></Subtitle>
            {/* <Description><i>How fast can you bankrupt Elon Musk?</i></Description> */}
        </HeaderRow>

    )
};

export { Header }