import React, { useState, useEffect } from "react";
import styled from 'styled-components'


const TwitterFeedParent = styled.div`
padding:5px;
margin: 15px 0px;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 5px;`

const TwitterFeedContainer = styled.div`
transition: 0.5s;
height: 300px;
overflow:hidden;
display:grid;
position:relative;
-webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
`

const TweetContainer = styled.div`
    background:white;
    font-family:"TwitterChirp", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    transition: 0.5s;
    position: relative;
    display: grid; 
    grid-template-columns: 50px 1fr; 
    grid-template-rows: 25px 1fr; 
    gap: 0px 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 16px 12px;
    margin: 5px;
    max-height: 400px;
    ${({ index }) => {
        const sharedAnimation = `
        max-height: 400px;
        opacity: 1;
        `
        return index === 0 ? `
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            max-height: 0;
            opacity: 0;
            animation: grow 0.5s ease-in-out forwards;
            -webkit-animation: grow 0.5s ease-in-out forwards;
            @-webkit-keyframes grow { to { ${sharedAnimation} }}
            @keyframes grow { to { ${sharedAnimation} }}
    `: ""
    }};
`;

const ProfileImage = styled.div`
background-image: url("https://hips.hearstapps.com/hmg-prod/images/musk-weed-1536332069.jpg");
border-radius: 50%;
background-size: cover;
background-position: center;
width: 48px;
height: 48px;
transition: 0.5s;
`;
const TweetInfo = styled.div``;
const TweetText = styled.div``;


function TwitterFeed({ tweets }) {
    return <TwitterFeedParent><TwitterFeedContainer>
        {tweets.map((tweet, i) => (
            <Tweet key={tweet.id} index={i} text={tweet.text} date={tweet.date} />
        ))}
    </TwitterFeedContainer></TwitterFeedParent>
}

function Tweet({ text, index, date }) {
    return (<TweetContainer index={index}>
        <ProfileImage />
        <TweetInfo><b>Elon Musk</b> @elonmusk · {date}</TweetInfo>
        <div></div>
        <TweetText>{text}</TweetText>
    </TweetContainer>)
}

export default TwitterFeed;