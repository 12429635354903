import styled from "styled-components";

const topColor = "#555555";
const bottomColor = "#333";

const gradient = {
    style: "linear-gradient(to right, #29323c 0%, #485563  51%, #29323c  100%)",
    bonus: "linear-gradient(to right, #fa709a 0%, #fee140 100%)",
    upgrade: "linear-gradient( 132.6deg,  rgba(71,139,214,1) 23.3%, rgba(37,216,211,1) 84.7% );",
    size: "200% auto"
}

const style = `
    animation: fadein 0.2s;
    @keyframes fadein {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
    padding: 17px 20px;
    border-radius: 10px;
    border: 0;
    background-color: ${topColor};
    letter-spacing: 1.5px;
    font-size: 15px;
    cursor: pointer;
    transition: all .3s ease;
    box-shadow: ${bottomColor} 0px 10px 0px 0px;
    color: #fff;
    font-family: 'Noto Sans', sans-serif;

   &:hover {
    box-shadow: ${bottomColor} 0px 7px 0px 0px;
   }

   &:active {
    background-color: ${topColor};
    /*50, 168, 80*/
    box-shadow: ${bottomColor} 0px 0px 0px 0px;
    transform: translateY(5px);
    transition: 200ms;
   }
`


const ShopTile = styled.div<{ type: string; cost: number; muskCoin: number; upgrade: any; background:string; }>`
${({ type }) => {
        switch (type) {
            case "upgrade": {
                return `
                background: #ad5389; /* fallback for old browsers */
                background: -webkit-linear-gradient(to right, #ad5389, #3c1053); /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to right, #ad5389, #3c1053); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */              
                display: grid; 
                grid-template-columns: 1fr; 
                grid-template-rows: 1fr 1fr; 
                gap: 0px 0px; 
                grid-template-areas: 
                "."
                "."
                "Cost";`
            }
            case "bonus": {
                return `
                background: #0f0c29;  /* fallback for old browsers */
                background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
                background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

                display: grid;
                grid-template-columns: 1fr; 
                grid-template-rows: 1fr; 
                gap: 0px 0px; 
                grid-template-areas: 
                "."
                "."
                  "Cost";`
            }
            default: {
                return `
                background-image:${gradient.style};
                grid-template-columns: 80px 1fr 80px; 
                grid-template-rows: 1fr 0.75fr; 
                gap: 0px 0px;
                grid-template-areas: 
                "Number . PerSec"
                "Number Cost PerSec";`
            }
        }
    }}

    // Background Image
    ${({ upgrade }) => {
        if (upgrade.background) {
            return `
        position: relative;
        &:before {
            width:100%;
            content: "";
            height:100%;
            background-image:url("${process.env.PUBLIC_URL}/investments/${upgrade.background}");
            background-size:50px;
            background-repeat: no-repeat;
            background-position: 5px 50%;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.50;
        }`
        }
    }}

    ${({ background }) => {
        if (background) {
            return `
        position: relative;
        &:before {
            width:100%;
            content: "";
            height:100%;
            background-image:url("${process.env.PUBLIC_URL}/investments/${background}");
            background-size:30px;
            background-repeat: no-repeat;
            background-position: 5px 5px;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            opacity: 0.50;
        }`
        }
    }}

    // Button Gradient 
    background-size:${gradient.size};
    background-repeat: no-repeat;
    background-position: 0 0%;
        
    display: grid; 
    user-select: none;
    text-align: center;
    margin: 10px 0px;
    ${style}
    ${({ cost, muskCoin }) => muskCoin < cost ?
        `background-color: #e7e7e7!important;
        color: black!important;
        box-shadow: none!important;
        transform: none!important;
        background-image: none;
        opacity:0.8;
        &:before {
            opacity:0;
        }
    ` : null}
    & > p {
        margin: 5px auto;
    }
  }
`

export { ShopTile }

