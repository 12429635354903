import React, { useState, useEffect, useRef } from "react";
import Tweets from "./content/tweets"
import Dashboard from "./components/dashboard";
import Canvas from "./components/canvas.tsx";
import { confettiCanvas, bankruptcy } from "./helpers/helpers.ts"
import { applyUpgradesToInvestment, getClickBonus, generateUpgrades, applyTeslaStockPrice, Investment } from "./helpers/upgrades.ts";
import { PopupModual } from "./components/Popup";
import content from "./content/content.json"
import { useInterval } from "./hooks";

const timer = 1000;
const TWO_HUNDRED_BILLION = 200000000000;

function Game({ savedMuskCoin, totalIncomeLost, savedUpgrades }: { savedMuskCoin: number, totalIncomeLost: number, savedUpgrades: Investment[] }) {
  // Clear local storage if on localhost or ngrok
  const hostname = window.location.hostname;

  // Modal Settings
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);



  const ref = useRef();
  const flipRef = useRef(null);
  const [isBankrupt, setBankrupt] = useState(totalIncomeLost > TWO_HUNDRED_BILLION)
  const [muskCoin, setMuskCoin] = useState(savedMuskCoin);
  const [previousMuskCoin, setPreviousMuskCoin] = useState(0);
  const [cps, setCps] = useState(0);
  const [incomeLost, setIncomeLost] = useState(totalIncomeLost)
  const [upgrades, setInvestments]: [Investment[], any] = useState(generateUpgrades(savedUpgrades));
  const [tweets, addTweet] = useState([
    { text: "Converting my debt into a currency I'm calling Musk Coin ₮", id: "0", date: "1h" },
    { text: "Let that sink in.", id: "1", date: "2h" },
    { text: "Hey everyone look at me", id: "2", date: "3h" },
  ]);

  // Increment the number of MuskCoin the player has by the given amount
  function earnMuskCoin(amount: number) {
    // setPreviousMuskCoin(muskCoin);
    setMuskCoin(muskCoin + amount);
    setIncomeLost(prevIncome => prevIncome + (amount));
    if ((incomeLost >= TWO_HUNDRED_BILLION) && !isBankrupt) {
      setBankrupt(true)
      bankruptcy();
      setModalOpen(true);
    }
  }

  function createTweet() {
    // Get a random item from an array
    const randomTweet = Tweets[Math.floor(Math.random() * Tweets.length)];

    // Add item to array, remove first item if more than 4

    // generate a random id
    const id = Math.random().toString(36).substring(2, 9);

    const newTweets = [{ text: randomTweet, id, date: "Now" }, ...tweets].slice(0, 4)
    addTweet(newTweets);
  }

  function calculateCPS(upgrades: Investment[]): number {
    const newUpgrades: Investment[] = upgrades.map(applyUpgradesToInvestment);
    return (newUpgrades.reduce((total, upgrade) => total + upgrade.cps * upgrade.owned, 0));
  }

  // Handle clicking the Tweet Button to earn more Musk Coin
  function handleClickTweet(event: { pageX: any; pageY: any; }) {
    const { pageX, pageY } = event;
    createTweet();
    const clickUpgrades = upgrades.find(f => f.id === "clickUpgrades");

    const bonusMultiplier = clickUpgrades ? getClickBonus(clickUpgrades) : 1;
    const amount = bonusMultiplier;
    if (muskCoin < 250) {
      setPreviousMuskCoin(muskCoin);
    }
    if (hostname.includes("localhost") || hostname.includes("ngrok")) {
      earnMuskCoin(1);
    } else {
      earnMuskCoin(amount);
    }
    // @ts-expect-error
    ref.current.tweet(pageX, pageY, `+₮${amount}`);
  }

  // Handle purchasing an upgrade
  async function handlePurchase(event: any, investmentId: string, upgradeId: string) {
    const { clientX, clientY } = event;
    const index = upgrades.findIndex((u) => u.id === investmentId);
    const investment: Investment = upgrades.find((u) => u.id === investmentId);
    if (investment && upgradeId) {
      const upgrade = investment.upgrades.find((u: any) => u.id === upgradeId);
      if (!upgrade) return;
      if (muskCoin >= upgrade.cost) {
        confettiCanvas(clientX, clientY, "upgrade")
        setInvestments((prevUpgrades: Investment[]) => {
          const newUpgrades = [...prevUpgrades];
          const chosenUpgrade = newUpgrades.find(f => f.id === investmentId)?.upgrades.find((f: any) => f.id === upgradeId);
          if (chosenUpgrade) chosenUpgrade.owned += 1;
          return newUpgrades;
        });
        setMuskCoin(prevMuskCoin => {
          setPreviousMuskCoin(prevMuskCoin);
          return prevMuskCoin - upgrade.cost
        });
      }
      return;
    }
    if (investment && muskCoin >= investment.cost) {
      confettiCanvas(clientX, clientY)
      setInvestments((prevUpgrades: Investment[]) => {
        const newUpgrades = [...prevUpgrades];
        newUpgrades[index] = { ...investment, owned: investment.owned + 1 };
        return newUpgrades;
      });
      setMuskCoin(prevMuskCoin => {
        setPreviousMuskCoin(prevMuskCoin);
        return prevMuskCoin - investment.cost;
      });
    }
  }

  // Run every time the upgrades change to update the CPS.
  useEffect(() => {
    const calculatedUpgrades = calculateCPS(upgrades);
    setCps(calculatedUpgrades);
  }, [upgrades]);

  // Generate MuskCoin automatically over time
  useInterval(() => {
    // Your custom logic here
    const fraction = timer / 1000;
    setPreviousMuskCoin(muskCoin);
    earnMuskCoin(cps * fraction);
  }, timer);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const fraction = timer / 1000;
  //     earnMuskCoin(cps * fraction);
  //   }, timer);
  //   return () => clearInterval(interval);
  // }, [cps, upgrades, incomeLost, isBankrupt]);



  useEffect(() => {
    const interval = setInterval(() => {
      const autoUpgrade = upgrades.find(f => f.id === "autoTweeter");
      if (autoUpgrade && autoUpgrade.owned > 0) {
        createTweet();
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [upgrades]);


  // Save state to local storage on update
  useEffect(() => {
    localStorage.setItem("muskCoin", muskCoin.toString());
    localStorage.setItem("upgrades", JSON.stringify(upgrades));
    localStorage.setItem("incomeLost", incomeLost.toString());
  }, [muskCoin, upgrades, incomeLost]);

  // Set Tesla Stock Price Every 5 Seconds
  useEffect(() => {
    const interval = setInterval(() => {
      const newUpgrades: Investment[] = upgrades.map(applyTeslaStockPrice);
      setInvestments(newUpgrades)
    }, 5000);
    return () => clearInterval(interval);
  }, [upgrades]);

  const { heading, subheading, closeText, content: modalcontent } = content.bankruptModal;

  return (
    <div>
      <Canvas ref={ref} />
      <PopupModual open={modalOpen} close={closeModal} heading={heading} subheading={subheading} closeText={closeText} content={modalcontent}></PopupModual>
      <Dashboard
        netWorth={TWO_HUNDRED_BILLION - incomeLost}
        muskCoin={muskCoin}
        previousMuskCoin={previousMuskCoin}
        handleClickTweet={handleClickTweet}
        cps={cps}
        incomeLost={incomeLost}
        flipRef={flipRef}
        upgrades={upgrades}
        handlePurchaseUpgrade={handlePurchase}
        tweets={tweets} />
    </div>
  );
}

export default Game;