import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "./Popup.css"
import ReactMarkdown from "react-markdown"

const Modual = ({ open, close, content, heading, subheading, actions, footer }) => {
    return (
        <Popup
            open={open}
            onClose={close}
            modal
            nested
        >
            {(close) => (
                <div className="modal">
                    <button className="close" onClick={close}>
                        &times;
                    </button>
                    <div className="header">
                        <h2>{heading}</h2>
                        {subheading && <h3>{subheading}</h3>}
                    </div>
                    <div className="content">
                        <ReactMarkdown>{content}</ReactMarkdown>
                    </div>
                    <div className="actions">
                        {actions}
                    </div>
                    <div className="footer">{footer}</div>
                </div>
            )}
        </Popup>
    );
}


const PopupModual = ({ open, close, heading, subheading, closeText, content }) => {
    const actions = (
        <button
            className="button"
            onClick={() => { close(); }}>
            {closeText}
        </button>)
    const footer = (<a href="https://twitter.com/intent/tweet?text=I%20bankrupted%20Elon%20Musk!%20https%3A//elonquest.com%20%23ElonQuest">Tell everyone on Twitter</a>)
    return (
        Modual({open, close, heading, subheading, content, actions, footer})
    );
}

const ClearDataModual = ({open, close, clearData}) => {
    const heading = "Clear All Data?"
    const content = "Are you sure you want to clear all data? This will reset your progress, and you won't be able to get it back.  \n   In other words, Elon wins."
    const actions = (
        <><button
            className="button"
            onClick={() => {
                clearData();
                close();
            }}>
            {"Yes"}
        </button><button
            className="button"
            onClick={() => { close(); }}>
                {"No"}
            </button></>)

    return (
        Modual({ open, close, heading, content, actions })
    );
}



export { PopupModual, ClearDataModual };