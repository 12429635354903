import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { ClearDataModual } from "./components/Popup";
import Game from "./Game.tsx";
import Footer from "./components/Footer.tsx"

const Options = styled.div``

const App = () => {
  const savedMuskCoin = Number(localStorage.getItem("muskCoin")) || 0;
  const totalIncomeLost = Number(localStorage.getItem("incomeLost")) || 0;
  const savedUpgrades = JSON.parse(localStorage.getItem("upgrades"));
  const [shouldClearData, setShouldClearData] = useState(false);
  const [askShouldClearData, setAskShouldClearData] = useState(false);
  const closeModal = () => setAskShouldClearData(false);
  const clearData = () => setShouldClearData(true);
  if (!!shouldClearData) {
    localStorage.clear();
    window.location.reload();
  } else {
    const clearButton = (<Options><button onClick={() => {
      setAskShouldClearData(true);
    }}>Clear Data</button>
    </Options>)
    return (
      <>
        <ClearDataModual open={askShouldClearData} close={closeModal} clearData={clearData} />
        <Game
          savedMuskCoin={savedMuskCoin}
          totalIncomeLost={totalIncomeLost}
          savedUpgrades={savedUpgrades}>
        </Game>
        <Footer otherButtons={clearButton}></Footer>
      </>
    )
  }

}

export default App;