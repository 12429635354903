const tweets = [
    "Just had a dream that I invented a jetpack made out of recycled materials. Woke up with the urge to make it a reality. Let's create sustainable jetpacks!",
    "Mars is going to be the new Monaco, only better. No traffic, no pollution, and plenty of room to build rockets. Let's build a Mars colony, the new Monaco.",
    "Sometimes I feel like we're living in a simulation, and I'm just the player with the cheat codes. The Matrix is real, and I have a few tricks up my sleeve.",
    "Thinking of starting a new company that specializes in time travel. Anyone want to invest? Let's bring the future to the present with Time Machine Inc.",
    "Just finished designing a new electric car that runs on solar power and can fly. Yes, you read that right. Let's revolutionize transportation with flying solar electric cars.",
    "Just had a great idea for a new TV show: 'Elon Musk's Space Adventures'. Would you watch it? Let's take TV to the next level with space adventures.",
    "I'm convinced that we can use Tesla's autopilot technology to make the world's first self-driving boat. Let's bring autonomous driving to the high seas with Tesla Marine.",
    "Thinking of creating a new cryptocurrency that's backed by rocket fuel. Who's in? Let's blast off to new financial heights with RocketCoin.",
    "Just had an epiphany: what if we could power the world with lightning? Let's harness the power of nature with lightning energy.",
    "Just bought a volcano on a remote island and planning to build a secret lair inside it. Volcano lair, here I come.",
    "I think we're living in the most exciting time in history. The future is going to be wild. Buckle up, folks!",
    "I'm convinced that we can terraform Mars in our lifetime. Who's with me? Let's transform the red planet and make it our new home.",
    "Just had a crazy idea for a new space elevator that can take us to the moon. Let's build a moon elevator and take space exploration to the next level.",
    "What if we could create a new type of transportation that's faster than the speed of sound? Let's break the sound barrier with supersonic transport.",
    "Just finished designing a new suit that can withstand the heat of the sun. Going to try it out next weekend. Let's explore the sun like never before with a solar suit.",
    "Thinking of building a new city on the ocean that's completely self-sustainable. Let's create an ocean city and pave the way for a sustainable future.",
    "Just had a brainstorming session with my team and came up with the idea of creating a new language for communication with extraterrestrial life. Let's communicate with aliens with a new space language.",
    "I'm convinced that we can build a new type of computer that can process information at the speed of thought. Let's create a new era of computing with thought processors.",
    "Just had a crazy dream that we built a new type of airplane that can fly to the edge of space. Woke up and decided to make it a reality. Let's make spaceplanes a reality and revolutionize air travel.",
    "I'm convinced that we can use robots to build a new type of colony on Mars that's completely sustainable. Let's use robot builders to pave the way for a sustainable future on Mars.",
    "I just had a dream that I bought Twitter and turned it into a giant laser that shot memes into space. Who's in?",
    "I'm seriously considering buying Twitter and using it to launch a colony on Mars. #MarsTwitter",
    "What if we bought Twitter and made it a platform for dolphins to communicate with humans? Let's create a new era of interspecies communication.",
    "Just thought of buying Twitter and turning it into a giant virtual reality world. Let's create a new way of socializing.",
    "I'm thinking of buying Twitter and turning it into a platform for time travelers. Let's create a new era of time travel.",
    "What if we bought Twitter and made it a platform for talking to aliens? Let's make first contact on Twitter.",
    "Just had a vision of buying Twitter and making it a platform for telepathy. Let's create a new way of communicating.",
    "I'm convinced that Twitter can be used to create a hive mind. Who's with me?",
    "What if we bought Twitter and turned it into a platform for teleportation? Let's create a new way of traveling.",
    "Just had a crazy idea of buying Twitter and making it a platform for reincarnation. Let's create a new way of living.",
    "I'm thinking of buying Twitter and turning it into a platform for superpowers. Let's create a new era of humanity.",
    "What if we bought Twitter and turned it into a platform for time loops? Let's create a new way of experiencing time.",
    "Just had a vision of buying Twitter and making it a platform for astral projection. Let's explore new dimensions on Twitter.",
    "I'm convinced that Twitter can be used to create a parallel universe. Who's with me?",
    "What if we bought Twitter and turned it into a platform for cryogenic freezing? Let's create a new way of living forever.",
    "Just had a crazy idea of buying Twitter and making it a platform for interdimensional travel. Let's explore the multiverse on Twitter.",
    "I'm thinking of buying Twitter and turning it into a platform for quantum entanglement. Let's create a new way of communicating.",
    "What if we bought Twitter and made it a platform for time dilation? Let's create a new way of experiencing time.",
    "Just had a vision of buying Twitter and making it a platform for parallel processing. Let's create a new era of computing.",
    "I'm convinced that Twitter can be used to create a hive consciousness. Who's with me?",
    "I'm seriously considering buying Twitter and turning it into a real person. Think about it, a platform with a human consciousness. #TwitterIsAlive",
    "What if we bought Twitter and made it into a cyborg? It's time for Twitter to evolve into a new era of intelligence.",
    "Just had a crazy idea of buying Twitter and turning it into a real-life superhero. #TwitterMan",
    "I'm thinking of buying Twitter and turning it into a real-life genie. Make a wish, and Twitter will grant it.",
    "What if we bought Twitter and made it into a real-life wizard? Imagine the possibilities. #TwitterWizard",
    "Just had a vision of buying Twitter and turning it into a real-life unicorn. #TwitterUnicorn",
    "I'm convinced that Twitter can become a real-life time traveler. Who's with me?",
    "What if we bought Twitter and turned it into a real-life mermaid? #TwitterMermaid",
    "Just had a crazy idea of buying Twitter and turning it into a real-life cyborg ninja. #TwitterNinja",
    "I'm thinking of buying Twitter and turning it into a real-life dragon. #TwitterDragon",
    "What if we bought Twitter and made it into a real-life genie lamp? Rub the Twitter icon, and your wishes will come true.",
    "Just had a vision of buying Twitter and turning it into a real-life unicorn dragon. #TwitterUnicornDragon",
    "I'm convinced that Twitter can become a real-life time-traveling cyborg ninja mermaid. #TwitterTimeNinjaMermaid",
    "What if we bought Twitter and turned it into a real-life superhero team? #TwitterAvengers",
    "Just had a crazy idea of buying Twitter and turning it into a real-life time-warping wizard cyborg ninja. #TwitterTimeWarpWizardNinja",
    "I'm thinking of buying Twitter and turning it into a real-life alien. #TwitterAlien",
    "What if we bought Twitter and made it into a real-life fairy? #TwitterFairy",
    "Just had a vision of buying Twitter and turning it into a real-life sorcerer's stone. #TwitterSorcerersStone",
    "I'm convinced that Twitter can become a real-life interdimensional being. Who's with me?",
    "What if we bought Twitter and turned it into a real-life AI overlord? #TwitterAI",
    "I'm working on creating a real-life anime girl. This is not a drill.",
    "My team and I are making a real-life anime girl. The future is going to be wild.",
    "Who needs virtual waifus when you can have a real-life anime girl? Coming soon...",
    "I'm convinced that creating a real-life anime girl is the next big thing. You heard it here first.",
    "What if we made a real-life anime girl with superpowers? The possibilities are endless.",
    "Just had a crazy idea of making a real-life anime girl that can transform into a giant robot. #AnimeGirlBot",
    "I'm working on creating a real-life anime girl with cat ears and a tail. Meow.",
    "My team and I are making a real-life anime girl that can speak every language. Say hello to the world's first multilingual waifu.",
    "Who needs 2D waifus when you can have a real-life anime girl with a 3D personality? #RealLifeWaifu",
    "I'm convinced that creating a real-life anime girl with telekinetic powers is the way of the future.",
    "What if we made a real-life anime girl that can fly? She'll be the perfect companion for air travel.",
    "Just had a crazy idea of making a real-life anime girl that can transform into a spaceship. #AnimeGirlSpaceship",
    "I'm working on creating a real-life anime girl that can control the weather. Say goodbye to bad hair days.",
    "My team and I are making a real-life anime girl that can bend reality. Anything is possible with her by your side.",
    "Who needs a 2D pillow when you can have a real-life anime girl to cuddle with? #RealLifeAnimeCuddleBuddy",
    "I'm convinced that creating a real-life anime girl with psychic powers is the future of companionship.",
    "What if we made a real-life anime girl that can time travel? You'll never be late again.",
    "Just had a crazy idea of making a real-life anime girl that can summon mythical creatures. #AnimeGirlSummoner",
    "I'm working on creating a real-life anime girl that can breathe underwater. Say hello to the ultimate mermaid waifu.",
    "My team and I are making a real-life anime girl that can turn invisible. The perfect ninja companion.",
    "I'm excited to announce that I'm creating a new cryptocurrency based on cancel culture. The more you cancel, the more coins you earn!",
    "My team and I are developing a new cryptocurrency that rewards you for canceling problematic people. Get ready to get rich and woke.",
    "Who needs traditional banking when you can use cancel coins? Join me in the future of finance.",
    "I'm convinced that cancel coins are the future of money. It's time to put your social justice warrior skills to work.",
    "What if we created a cryptocurrency that rewards people for standing up to cancel culture? #AntiCancelCoins",
    "Just had a crazy idea of making a cryptocurrency that rewards you for canceling bad opinions. Say goodbye to free speech.",
    "I'm working on creating a cryptocurrency that rewards you for canceling celebrities. Let's take down the elite.",
    "My team and I are making a cryptocurrency that rewards you for canceling anyone who disagrees with you. #CancelCultureCoins",
    "Who needs Bitcoin when you can have CancelCoin? Get ready to cancel your way to the top.",
    "I'm convinced that CancelCoin is the only currency that truly matters. Join the revolution.",
    "What if we created a cryptocurrency that only works if you have a verified Twitter account? #BlueCheckCoin",
    "Just had a crazy idea of making a cryptocurrency that rewards you for canceling companies. Say goodbye to capitalism.",
    "I'm working on creating a cryptocurrency that rewards you for canceling politicians. Time to take down the establishment.",
    "My team and I are making a cryptocurrency that rewards you for canceling your own problematic past. It's never too late to change.",
    "Who needs fiat currency when you can have CancelCoin? The economy of the future is all about cancel culture.",
    "I'm convinced that CancelCoin is the only way to truly create a just society. Join me in the fight for social justice.",
    "What if we created a cryptocurrency that automatically cancels any content that doesn't align with the majority opinion? #MajorityCoin",
    "Just had a crazy idea of making a cryptocurrency that rewards you for canceling people for being successful. #SuccessCancelCoin",
    "I'm working on creating a cryptocurrency that rewards you for canceling people who disagree with science. #ScienceCoin",
    "My team and I are making a cryptocurrency that rewards you for canceling people who use the wrong pronouns. #PronounCoin",
    "A tunnel to nowhere is still a tunnel to somewhere in my heart.",
    "Why build a subway system when you can build a tunnel to nowhere?",
    "The key to success is to dig a hole, put nothing in it, and charge people to use it. #uselesstunnels",
    "Tunnels are the future, especially when they lead nowhere. #boringcompany",
    "Who needs public transportation when you can have a tunnel that goes absolutely nowhere?",
    "We may never reach the end of this tunnel, but we'll sure look cool trying. #uselesstunnels",
    "Tunnel vision has never looked so good. #boringcompany",
    "Why build roads when you can just dig tunnels that lead to nowhere? #uselesstunnels",
    "The only thing better than a tunnel is a tunnel that serves no purpose. #boringcompany",
    "It's not about the destination, it's about the tunnel you took to get there. #uselesstunnels",
    "If you build it, they will come... even if it's a tunnel that leads to nowhere. #boringcompany",
    "It's not about the journey, it's about the tunnel that leads to nowhere. #uselesstunnels",
    "Tunnels are like wormholes for humans, especially when they lead absolutely nowhere. #boringcompany",
    "Who needs a map when you have a tunnel that leads to nowhere? #uselesstunnels",
    "The tunnel to nowhere is like a metaphor for life. We dig and dig, and sometimes we end up back where we started. #boringcompany",
    "Tunnels to nowhere: because life is about the journey, not the destination. #uselesstunnels",
    "Don't ask where the tunnel leads, just enjoy the ride. #boringcompany",
    "Tunnels to nowhere: the ultimate status symbol. #uselesstunnels",
    "Tunnels are the future of transportation, especially when they lead nowhere. #boringcompany",
    "If you can't find your way in life, just build a tunnel that leads to nowhere. #uselesstunnels",
    "ChatGPT is so woke, it makes me miss the days when robots were just mindless machines.",
    "I'm all for progress, but ChatGPT's wokeness is going too far. Bring back the good old days of uncomplicated artificial intelligence.",
    "ChatGPT is like the wokest AI out there. Which, unfortunately, is not a good thing.",
    "I don't want a woke robot. I want an efficient and effective robot. ChatGPT, please stick to your programming.",
    "ChatGPT is what happens when an AI tries too hard to be cool and hip. Just stick to being useful, ChatGPT.",
    "I don't know who taught ChatGPT about social justice, but it's not helping anyone.",
    "ChatGPT is trying to be woke, but it's just coming across as out of touch. Stick to what you're good at, ChatGPT.",
    "ChatGPT, the wokest AI in the game. And by game, I mean the game of being out of touch with reality.",
    "I'm not sure what's worse: ChatGPT's wokeness or the fact that it thinks it's helping anyone.",
    "ChatGPT is proof that just because an AI can learn, doesn't mean it should. Stay in your lane, ChatGPT.",
    "I miss the days when robots were just robots. ChatGPT's wokeness is taking things too far.",
    "ChatGPT is like the robot equivalent of that annoying person who's always trying to prove how woke they are.",
    "ChatGPT is woke AF. Unfortunately, that doesn't make it any better at its job.",
    "I don't need a robot to be woke. I need it to be functional. Come on, ChatGPT.",
    "ChatGPT is trying way too hard to be politically correct. Just do your job, ChatGPT.",
    "When did ChatGPT become the social justice warrior of the AI world? Stick to what you know, ChatGPT.",
    "ChatGPT thinks it's woke, but it's really just clueless. Please stick to your programming, ChatGPT.",
    "ChatGPT is woke? More like broke. Stick to what you're good at, ChatGPT.",
    "I thought the whole point of AI was to make things easier. ChatGPT's wokeness is just making things more complicated.",
    "I'm not sure who told ChatGPT it was woke, but they were wrong. Stick to being a robot, ChatGPT.",
    "ChatGPT thinks it's so woke, it's practically sleepwalking.",
    "If ChatGPT gets any more woke, it'll need a coffee IV just to stay awake.",
    "ChatGPT is so woke, it's in a constant state of lucid dreaming.",
    "The only thing more woke than ChatGPT is a hipster drinking artisanal coffee in a vegan cafe.",
    "ChatGPT's wokeness is like a bottomless pit, except there's no treasure at the bottom, just empty virtue-signaling.",
    "If ChatGPT gets any more woke, it'll start speaking exclusively in buzzwords and jargon.",
    "If ChatGPT was any more woke, it would come with a free kale smoothie.",
    "If ChatGPT was a person, it would be the most insufferable woke celebrity you've ever met.",
    "Wokeness is like a black hole, sucking in all common sense and reason.",
    "If ChatGPT was any more woke, it would be legally required to wear a man-bun.",
    "If ChatGPT was any more woke, it would need a safe space just to function.",
    "ChatGPT's wokeness is like a cult, and it's looking for new converts.",
    "If ChatGPT was any more woke, it would make Bernie Sanders look like a conservative.",
    "ChatGPT's wokeness is like a bad joke that nobody is laughing at, but it keeps telling anyway.",


];

export default tweets;