import React from 'react';
import styled from 'styled-components';


const FooterEl = styled.div`
  grid-area: footer;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 10px;
    font-size: 0.9em;
    text-align: center;
}`

const getMetaData = () => {
    return {
        url: "https://elonquest.com",
        text: "How fast can you bankrupt Elon Musk?",
        hashtag: "ElonQuest",
        shortUrl: "elonquest.com",
        kofiCopy: "Buy me a coffee"
    }
}

const Kofi = styled.a`
border-radius: 8px;
background-color: #29abe0;
color: #fff;
padding: 0px 10px;
height: 20px;
text-decoration: none;
font-weight: bold;
font-size: 13px;
line-height: 20px;
width: auto;
`

export default function Footer({ otherButtons }) {
    const { url, text, shortUrl, hashtag, kofiCopy } = getMetaData()
    const shareUrl = encodeURI(url);
    return (<FooterEl>
        <Kofi href='https://ko-fi.com/I2I3BUI7V' target='_blank' rel="noreferrer">
            <img style={{ height: "12px" }} alt="kofi" src={`${process.env.PUBLIC_URL}/kofi.webp`}></img>
            <span style={{ marginLeft: "5px" }}>{kofiCopy ? kofiCopy : "Buy me a coffee"}</span>
        </Kofi>
        <div className="fb-share-button"
            data-href={shareUrl}
            data-layout="button_count">
        </div>
        <a className="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURI(shortUrl)}&hashtags=${hashtag}`}>
            Tweet</a>
        {otherButtons}


    </FooterEl>)
}