import React, { useState, useEffect } from "react";
import { numberToCurrency, shortenNumber } from "../helpers/helpers.ts"
import { applyUpgradesToInvestment } from "../helpers/upgrades.ts"
import styled from "styled-components";
import ReactMarkdown from "react-markdown"
import { ShopTile } from "./shop-title.tsx";

const Owned = styled.div`
    grid-area: Number;
    align-self: center;
    position:relative;
    font-size:1.1rem;
    `;

const ItemName = styled.div`
    font-weight: bold;
    ${({ align }) => align ? `text-align: ${align};` : ""}
`;

const Cost = styled.div`
font-weight: bold;
grid-area: Cost;
${({ cost, muskCoin }) => muskCoin >= cost ? "color: #8ac926;" : "color: #ff595e;"}
position:relative;
`;

const PerSec = styled.div`
font-weight: bold;
grid-area: PerSec;`

const Description = styled.div`
// Vertical center in grid
align-self: center;
font-style: italic;
& > p {
    margin: 5px auto;
}
`

const StorePanel = styled.div`
// border-radius:15px;
// background-color: #fafafa;
padding:15px;
max-width: 100%;
overflow-x: hidden;
`

const UpgradeGrid = styled.div`
    // display: grid;
    // grid-template-columns: repeat( auto-fill, minmax(250px, 1fr) );
    // gap: 10px;
    display: grid;
    grid-column: 1 / -1;
    grid-gap: calc(20px / 2);
    grid-template-columns: 10px;
    // grid-template-rows: minmax(250px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: 250px;
  
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    scroll-snap-align: start;
    padding-bottom: calc(.75 * 20px);
    margin-bottom: calc(-.25 * 20px);
    &:before,
    &:after {
        content: '';
        width: 10px;
    }
    & > div {
        scroll-snap-align: center;
        // padding: calc(var(--gutter) / 2 * 1.5);
        // display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
const InvestmentGrid = styled.div`
    display: grid;
`




function InvestmentTile({ upgrade, onPurchase, muskCoin, itemName, itemCost }) {
    return (<ShopTile upgrade={upgrade} muskCoin={muskCoin} cost={upgrade.cost} onClick={(e) => onPurchase(e, upgrade.id)}>
        <Owned>{shortenNumber(upgrade.owned)}</Owned>
        <ItemName>{itemName || upgrade.name}</ItemName>
        <PerSec>{`+₮${shortenNumber(upgrade.cps)}`}<br />{"/sec"}</PerSec>
        <Cost muskCoin={muskCoin} cost={upgrade.cost}>₮{itemCost || numberToCurrency(upgrade.cost, 0, 2)}</Cost>
        {/* <div>🟢 🟢 🔴</div> */}
    </ShopTile>)
}

function UpgradeTile({ upgrade, onPurchase, muskCoin, itemName, itemCost, investment }) {
    if (upgrade.bonus || upgrade.clickBonus) {
        return (<ShopTile background={investment.background} upgrade={upgrade} muskCoin={muskCoin} cost={upgrade.cost} type={"bonus"} onClick={(e) => onPurchase(e, investment.id, upgrade.id)}>
            <ItemName>{itemName || upgrade.name}</ItemName>
            <Description><ReactMarkdown>{`${upgrade.description} ${upgrade.effect || ""}`}</ReactMarkdown></Description>
            <Cost muskCoin={muskCoin} cost={upgrade.cost}>₮{itemCost || numberToCurrency(upgrade.cost, 0, 2)}</Cost>
        </ShopTile>)
    }

    return (<ShopTile background={investment.background} upgrade={upgrade} muskCoin={muskCoin} cost={upgrade.cost} type={"upgrade"} onClick={(e) => onPurchase(e, investment.id, upgrade.id)}>
        <ItemName>{`Upgrade ${itemName || investment.name} to ${itemName || upgrade.name}`}</ItemName>
        <Description><ReactMarkdown>{`${upgrade.description} ${upgrade.effect || ""}`}</ReactMarkdown></Description>
        <Cost muskCoin={muskCoin} cost={upgrade.cost}>₮{itemCost || numberToCurrency(upgrade.cost, 0, 2)}</Cost>
    </ShopTile>)
}


function Investment({ upgrade, onPurchase, incomeLost, muskCoin }) {
    if (upgrade.cost <= 0) {
        return null;
    }
    if (incomeLost > (upgrade.cost - 20) || upgrade.cost < 100) {
        return (
            <InvestmentTile upgrade={upgrade} onPurchase={onPurchase} muskCoin={muskCoin} />
        );
    }
    if (incomeLost > (upgrade.cost / 10)) {
        return (
            <InvestmentTile upgrade={upgrade} onPurchase={onPurchase} muskCoin={muskCoin} itemName={"???"} itemCost={"??"} />
        );
    }
    return null;
}

function Upgrade({ upgrade, onPurchase, incomeLost, muskCoin, investment }) {
    const index = investment.upgrades.findIndex(f => f.id === upgrade.id);
    const previousUpgradeOwned = index === 0 || investment.upgrades[index - 1].owned > 0;
    const requirementMet = upgrade.requirement ? investment.owned >= upgrade.requirement : true;
    if (!upgrade.cost || upgrade.cost <= 0) {
        return null;
    }

    // Show the next available upgrade for the investment.
    if (incomeLost > ((upgrade.cost / 10) + (upgrade.cost / 10)) && previousUpgradeOwned && requirementMet) {
        return (<UpgradeTile upgrade={upgrade} onPurchase={onPurchase} muskCoin={muskCoin} investment={investment} />);
    }
    // Show the next available upgrade for the clicker.
    if (investment.id === "clickUpgrades" && previousUpgradeOwned) {
        return (<UpgradeTile upgrade={upgrade} onPurchase={onPurchase} muskCoin={muskCoin} investment={investment} />);
    }
    return null;
}


function Store({ upgrades, onPurchase, incomeLost, muskCoin }) {
    const calculatedUpgrades = upgrades.map(applyUpgradesToInvestment);
    return (
        <StorePanel>
            <><h2>Upgrades</h2>
                <UpgradeGrid>
                    {calculatedUpgrades
                        .filter(f => f.owned > 0)
                        .map((investment) => (
                            investment.upgrades
                                .filter(f => f.owned <= 0)
                                .map((upgrade) => (
                                    <Upgrade key={upgrade.id} muskCoin={muskCoin} incomeLost={incomeLost} upgrade={upgrade} investment={investment} onPurchase={onPurchase} />
                                ))))}
                </UpgradeGrid></>
            <InvestmentGrid>
                <h2>Investments</h2>
                You have to spend money to lose money. Convert your wealth to debt, then leverage it to make more money. How? I don't know, it's a rich person thing. Let's just call it MuskCoin (₮).
                {calculatedUpgrades.map((upgrade) => (
                    <Investment key={upgrade.id} muskCoin={muskCoin} incomeLost={incomeLost} upgrade={upgrade} onPurchase={onPurchase} />
                ))}
            </InvestmentGrid>
        </StorePanel>
    );
}

export default Store;