import React, { useState, useEffect, useRef } from "react";
import { numberToCurrency } from "../helpers/helpers.ts"
import styled from 'styled-components'
import Store from "../components/store";
import TwitterFeed from "../components/twitter-feed";
import { Header } from "./header.tsx";
import { Flip } from "./Flip";
import { TweetButton } from "./TweetButton.tsx";
import CountUp from 'react-countup';
import { shortenNumber } from "../helpers/helpers";

const App = styled.div`
    display: grid;
    max-width: 1000px;
    margin: 0 auto;
    padding: 5px 20px;
    background:#fff;
    // slight shadow
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    `;

const Resources = styled.div`
margin-top:25px;
text-align:center;
& > p {
    font-family: 'Noto Sans', sans-serif;
    font-size: 1.25rem;
}`;

const PerSecond = styled.div`
    font-size: 1rem;
`

const NetWorth = styled.div`
    margin: 20px -10px;
    text-align: center;
    position: sticky;
    top: 0;
    background: #fafafa;
    z-index: 9;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
& > strong {
    font-weight:normal;
    font-size:1.5rem;
}
`

const FlipBoard = styled.div`
display:grid;
justify-content: center;
align-content: center;

gap: 4px;
grid-auto-flow: column;
&>.tick{
    font-size: 1.7rem;
}
@media (max-width: 768px) {
    &>.tick{
        font-size: 1.4rem;
    }
}

@media (max-width: 450px) {
    &>.tick{
        font-size: 1.2rem;
    }

@media (max-width: 350px) {
    &>.tick{
        font-size: 1rem;
    }
}
`

const Small = styled.p`
margin: 0;
font-size: 0.8rem;
`

const MuskCoin = styled.div`
font-family: 'Noto Sans', sans-serif;
top:60px;
z-index:99;
text-align:center;
`

const StaticTile = styled.div`
    color: #edebeb;
    background-color: #333232;
    padding: 4px 5px;
    font-weight: bold;
`

const Larger = styled.div`
font-size: 1.25rem;
`

function Dashboard({
    netWorth, muskCoin, previousMuskCoin, incomeLost,
    handleClickTweet, cps, upgrades,
    handlePurchaseUpgrade, tweets }) {
    // const muskCoinFormat = shortenNumber(Math.floor(muskCoin));
    const MuskCoinTile = (<MuskCoin>MuskCoin: <b>
        {/* {muskCoinFormat} */}
        <CountUp
            separator=","
            decimals={0}
            decimal="."
            delay={0}
            prefix="₮ "
            duration={1}
            end={muskCoin}
            useEasing={false}
            start={previousMuskCoin} /></b></MuskCoin>)
    const networth = <NetWorth><div>Net worth:</div>
        <FlipBoard><StaticTile>$</StaticTile><Flip value={netWorth} /></FlipBoard>
        {MuskCoinTile}
        <PerSecond><Small>(₮{numberToCurrency(cps)} per second)</Small></PerSecond>
    </NetWorth>

    return (
        <App>
            <Header></Header>
            {window.innerWidth > 768 && networth}
            <TwitterFeed tweets={tweets} />
            {window.innerWidth <= 768 && networth}
            <Resources>
                <p>Lose money with every tweet!</p>
                <TweetButton muskCoin={muskCoin} text={"Tweet"} handleClickTweet={handleClickTweet} />
                <PerSecond><p>₮{numberToCurrency(cps)} per second</p></PerSecond>
            </Resources>
            <Larger>{MuskCoinTile}</Larger>
            <Store muskCoin={muskCoin} upgrades={upgrades} incomeLost={incomeLost} onPurchase={handlePurchaseUpgrade} />
        </App>
    );
}

export default Dashboard;