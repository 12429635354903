import upgrades from "../content/upgrades.json";

// Functions to help determine upgrades.

interface Investment {
    id: string;
    name: string;
    cost: number;
    cps: number;
    owned: number;
    background?: string;
    upgrades: {
        id?: string;
        level?: number;
        name: string;
        cost: number;
        cps?: number;
        requirement?: number;
        description?: string;
        effect?: string;
        bonus?: number;
        clickBonus?: number;
        costBonus?: number;
        owned: number;
    }[];
}

const followersUpgradeTemplate = {
    "id": "tweetReach",
    "level": 1,
    "name": "Buy Twitter followers",
    "cost": 10,
    "clickBonus": 2,
    "owned": 0,
    "description": "**Double** your ₮ from tweets.",
}

const investments = upgrades.map((m) => {
    if (m.id !== "clickUpgrades") {
        return m;
    }
    const followersUpgrades = Array.from({ length: 15 }, (v, i) => ({
        ...followersUpgradeTemplate,
        cost: followersUpgradeTemplate.cost * (10 ** i),
        id: `tweetReach${i}`,
        level: i + 1,
    }));
    return { ...m, upgrades: followersUpgrades }

});

// Create a new array of upgrades with the owned amount added to each upgrade
const generateUpgrades = (owned: Investment[]): Investment[] => {
    const newUpgrades = owned
        ? investments.map((m) => {
            const ownedUpgrade = owned.find((f) => f.id === m.id);
            return ownedUpgrade
                ? {
                    ...m,
                    owned: ownedUpgrade.owned,
                    upgrades: m.upgrades.map((upgrade) => ({
                        ...upgrade,
                        owned:
                            ownedUpgrade.upgrades.find(
                                (u) => u.id === upgrade.id
                            )?.owned || 0,
                    })),
                }
                : m;
        })
        : investments;
    return newUpgrades;
}


const getClickBonus = (investment: Investment) => {

    // Check if there are any upgrades owned for the investment
    if (investment.upgrades.length === 0 || !investment.upgrades.some(u => u.owned)) {
        // No upgrades owned, return 1
        return 1;
    }

    // Multiply all of the bonus values together to get the total multiplier
    const bonusMultiplier = investment.upgrades.reduce((acc, curr) => acc * ((curr.owned && curr.clickBonus) || 1), 1);

    return bonusMultiplier;
}

const applyUpgradesToInvestment = (investment: Investment) => {
    // Check if there are any upgrades owned for the investment
    if (investment.upgrades.length === 0 || !investment.upgrades.some(u => u.owned)) {
        // No upgrades owned, return the original investment object
        return investment;
    }

    // Multiply all of the bonus values together to get the total multiplier
    const bonusMultiplier = investment.upgrades
        .reduce((acc, curr) => acc * ((curr.owned && curr.bonus) || 1), 1);

    const costMultiplier = investment.upgrades
        .reduce((acc, curr) => acc * ((curr.owned && curr.costBonus) || 1), 1);


    // Find the highest level upgrade owned by the user
    const highestUpgradeIndex = investment.upgrades.map(m => (m.owned && m.owned >= 1) && !!m.cps).lastIndexOf(true);
    const upgrade = investment.upgrades[highestUpgradeIndex];

    const highestCps = upgrade?.cps || investment.cps;

    // Multiply all of the bonus values together to get the total multiplier
    // Calculate the new CPS based on the upgrade multiplier
    const newCps = highestCps * bonusMultiplier;
    const newCost = investment.cost * costMultiplier;
    const name = upgrade?.name || investment.name;

    return { ...investment, name: name, cps: newCps, cost: newCost };
};

const applyTeslaStockPrice = (investment: Investment) => {
    if (investment.id !== "teslaShorts") {
        return investment
    }
    const random = Math.random() * 2 - 1;

    // if a 1 in 10 chance is true
    const newTeslaPrice = investment.cps + random
    return { ...investment, cps: newTeslaPrice };
}

export { applyUpgradesToInvestment, getClickBonus, generateUpgrades, applyTeslaStockPrice, Investment };