import "./canvas.css";
import twittericon from "../assets/twittericon.png";
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle, } from "react";

interface Item {
    x: number;
    y: number;
    vx: number;
    vy: number;
    alpha: number;
    text?: IText;
    r?: number;
}

interface IText {
    text: string;
    alpha: number;
    x: number;
    y: number;
}

// remove first items in array if array length over 20
const trimArray = (array: any[], max: number): any[] => (array.length > max ? array.slice(array.length - max).splice(0, array.length - max) : array);



function frameRenderer(this: any, size: { width: number; height: number; }, { birds, texts }: { birds: Item[], texts: Item[] }) {
    this.clearRect(0, 0, size.width, size.height);
    const drawImage = (image: string, x: number, y: number, width: number, height: number) => {
        const img = new Image();
        img.src = image;
        this.drawImage(img, x, y, width, height);
    }
    this.save();
    birds.forEach((bird, i) => {
        this.globalAlpha = bird.alpha;
        if (bird.r) {
            drawImage(twittericon, bird.x, bird.y, bird.r, bird.r);
        }
        this.globalAlpha = 1;
    })
    texts.forEach((text) => {
        this.globalAlpha = text.alpha;
        this.font = "20px Arial";
        this.fillStyle = "white";
        this.textAlign = "center";
        this.shadowOffsetX = 3;
        this.shadowOffsetY = 3;
        this.shadowColor = "rgba(0,0,0,0.6)";
        this.shadowBlur = 4;
        this.fillText(text.text, text.x, text.y);
        this.globalAlpha = 1;
    })
    this.restore();

}



const Canvas = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        tweet(x: number, y: number, text: string) {
            createBird(x, y);
            createText(x, y, text);
        }
    }));
    const canvasRef = useRef(null);
    const requestIdRef = useRef(0);
    const [birds, setBird] = useState([{ x: 0, y: 0, vx: 0, vy: 0, alpha: 0 }]);
    const [texts, setText] = useState([{ x: 0, y: 0, vx: 0, vy: 0, alpha: 0 }]);
    const { clientWidth, scrollHeight } = document.body;
    const size = { width: clientWidth, height: Math.max(scrollHeight, 1000) };

    const updateItem = (item: Item[], speed = 1, set: any) => {
        item.forEach((e, index: number) => {
            e.alpha = Math.max(0, e.alpha - 0.01);
            e.x += e.vx * speed;
            e.y += e.vy * speed;
        })
        set(item.filter(f => f.alpha > 0.));
    }

    const createItem = (x: number, y: number, text?: string) => {
        const { clientWidth, clientHeight } = document.body;
        size.width = clientWidth;
        size.height = Math.max(clientHeight, 750);
        const r = Math.random() * 10 + 20;
        return {
            text: text,
            x,
            y,
            r,
            vx: Math.random() * 10 - 5,
            vy: Math.random() * 10 - 5,
            alpha: 1
        };

    }

    const createBird = (x: number, y: number) => {
        // create a new array with 2 new birds
        const birdsArray = Array.from({ length: 2 }, () => createItem(x, y));
        const newBirds = trimArray([...birds, ...birdsArray], 20);
        setBird(newBirds);
    };

    const createText = (x: number, y: number, text: string) => {
        const newT = createItem(x, y, text);
        setText(trimArray([...texts, newT], 20));
    };

    const renderFrame = () => {
        if (!canvasRef.current || !canvasRef.current) return;
        const current = canvasRef.current as HTMLCanvasElement;
        const ctx = current.getContext("2d");
        updateItem(birds, 1, setBird);
        updateItem(texts, 0.2, setText);
        frameRenderer.call(ctx, size, { birds, texts });
    };

    const tick = () => {
        if (!canvasRef.current) return;
        renderFrame();
        requestIdRef.current = requestAnimationFrame(tick);
    };

    useEffect(() => {
        requestIdRef.current = requestAnimationFrame(tick);
        return () => {
            cancelAnimationFrame(requestIdRef.current);
        };
    }, [birds, texts]);

    return <canvas {...size} ref={canvasRef} />;
});

export default Canvas;
